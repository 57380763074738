.form-group-container{
  margin: 15px 0;
  border-radius: 10px;
  padding: 35px 15px 15px 15px;
  border-color: #c5c6c5;
  border-width: 1px;
  position: relative;
  height: calc( 100% - 30px );
  background-color: #FFF;
  border: 1px dashed var(--principal-color, #2A96FF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}

.form-group-container-title{
  color:  #2A96FF;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.full-width{
  width: 100%;
}
.ng2tel-field-label{
  margin-left: 50px;
}
mat-select-country{
  display: block;
  width: 100%;
}
mat-select-country mat-form-field{
  width: 100%;
}

.card-document {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  margin: 0px;
}
.card-title{
  color: #2A96FF;
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Nunito Sans', sans-serif;
}
.card-document-details {
  background-color: #EDF5FD;
  border-radius: 1rem;
  border: none;
  margin-right: 4%;
  padding: 20px;
  justify-content: center;
  text-align: center;
  overflow-x: auto;
  height: 250px;
}
.position-col{
  position: absolute;
  right: 0;
}
.list-dossier{
  background-color: #CDE3F9;
  padding: 0px;
  opacity: 0.7;
  /*height: 250px;*/
  margin-right: 38px;
  padding-top: 10px;
}
.title-glisse{
  color: rgba(36, 113, 190, 0.4);
}
.icon-color {
  color:rgba(42, 150, 255,0.4);
}
.file-element:hover {

  animation: shake 1s;
  animation-iteration-count: infinite;
}
.file-element {
  border-radius: 25px;
  padding: 17px;
  background-color: #2a96ff8a;
  min-height: 50px;
  color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.delete-file-btn:hover {
  color: #1a3b93;
  font-size: 22px;
}
.delete-file-btn {
  position: absolute;
  top: -4px;
  left: 1px;
  font-size: 20px;
  color: #2a96ff8a;
}
.show-file{
  display: inline-block;
  font-size: 25px
}
.title-element{
  width: 80%;
  display: inline-block;
}
.select-file {
  color: rgba(42, 150, 255, 0.4);
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  width: 250px;
  margin: auto;
  padding: 15px;
}

.select-file:hover {
  color: whitesmoke;
  background-color: rgba(42, 150, 255, .85);
}

.btn-document-list{
  width: 43px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #2A96FF;
  color: white;
  margin-bottom: 10px;
}
.btn-document-list[data-title]:hover::after {  /* button hover text */
  content: attr(data-title);
  padding-left: 18px;
  transition: all 0.3s ease-out;
  color:#2A96FF;
  width: max-content;
  position: absolute;
  right: 100%;
  top: 69px;
  font-weight: bolder;
  font-size: 14px;
}
.btn-document-upload{
  width: 43px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #2A96FF;
  color: white;
  margin-bottom: 10px;
}
.btn-document-upload[data-title]:hover::after {  /* button hover text */
  content: attr(data-title);
  padding-left: 18px;
  transition: all 0.3s ease-out;
  color:#2A96FF;
  width: max-content;
  position: absolute;
  right: 100%;
  top: 12px;
  font-weight: bolder;
  font-size: 14px;
}

.navbar-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
.navbar-center p{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: bolder;
  color: #6D6D6E;
}
.page-item{
  cursor: pointer;
}
.disabled {
  pointer-events:none;
  opacity:0.6;
}
.label-card-info{
  font-size: 14px;
  font-weight: bolder;
}
.alert-erreur{
  color: #e00c1f;
  font-size: small;
  font-weight: 500;
  padding: 6px;
}


.shadow-box-card-body{
  margin: 15px 0;
  border-radius: 15px;
  padding: 35px 15px 15px 15px;
  border-color: #c5c6c5;
  border-width: 1px;
  position: relative;
  height: calc( 100% - 30px );
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}


.header-bg-color{
  background-color: #fff;
}


.align-items{
  align-items: center;
}


/* start style Porte Documents */
.card-doc{
  max-width: 100%;
  height: 400px;
  border-radius: 15px 15px 0px 0px;
}

.card-doc-title{
  color: #2A96FF;
  font-size: 16px;
  font-weight: bold;
}
.title-card-details{
  font-size: 18px;
}
.btn-position-add-document{
  position: absolute;
  right: 22px;
  margin-top: -60px;
}

.add-file{
  cursor: pointer;
  margin-left: -10px;
  margin-right: -10px;
}

.div-list-exist{
  height: 322px;
  overflow-y: scroll;


border-radius: var(--main-system-16-px, 16px);
border: 1px solid  #E6E8EA;
background:  #EEF7FF;
}
.scrolling{
  overflow-y: scroll;
}
.scrolling::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrolling::-webkit-scrollbar-track {
  background: #EDF5FD; 
  opacity: 0.5;
}
.scrolling::-webkit-scrollbar-thumb {
  background: #95c5f4; 

}

/* start style collapse */

.folder-icon,.file-icon,.bi-three-dots-vertical {
  font-size: 24px
}

.file-name {
  text-decoration: none
}

/* end style collapse */

/* end style Porte Documents */

/* start style Porte Documents Affaire */

.card-bloc{
  border-radius: 0px  0px  15px 15px;
  max-width: 100%;
  height: 430px;
}

.div-list-no-exist{
  height: 322px;
  overflow-y: scroll;
  border-radius:  16px;
border: 1px solid  #E6E8EA;
background: #F9F9F9;
}
.label-title{
  font-size: 15px;
}

/* end style Porte Documents Affaire */

/* start style List Tache && Joindre des fichiers  */
.card-body-details{
  background-color: #EEF7FF;
  height: 322px;
  border-radius: 10px;
  /* padding: 15px; */

;
}
.card-body-details-empty{
  height: 140px;
}

.title-details-blue{
  color: #2A96FF;
  /* font-size: 18px !important; */
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  /* margin-left: 12px; */
}

.btn-details-add{
  position:absolute;
  right: 10px;
  top: 0px;
}
/* end style List Tache && Joindre des fichiers  */


.fileContainerChild{

  height: 218px;
  border-radius: 10px;
  /* padding: 15px; */
  overflow-y: scroll;
}
.fileComment{
  border: 1px solid #FBFBFB;
background: #FFF;
box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
height: 52px;}

.file-name{
  color: #959494;
font-family:  'Nunito Sans', sans-serif;
font-size: 10px;
font-style: italic;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}


.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2a96ff;
}
.deleteFile{
  position: absolute;
  right: 7px;
  border: none;
  background-color: transparent;

}

.file-name{
  color: #727677;
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.documentDiv{
  border-radius: 5px;
border: 1px solid var(--Gris-1, #E3E3E3);
background: #FFF;
padding: 10px;
/* shadow 1 */
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}
.blocDocChild{
  border: 1px solid #FBFBFB;
background: #FFF;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
padding: 10px;
}
.deleteFileDoc{
  border: none;
  background-color: white;

}
.cursor{
  cursor: pointer;
}
.card-folder,
.card-header {
  width: 30.16581rem;
  height: 4.375rem;
  border-radius: 0.3125rem;
}
.greyColor {
  color: #727677;
}
.light {
  border-radius: 0.3125rem;
  border: 1px solid #e2e2e2;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  /* width: 30.16581rem; */
  height: 4.375rem;
}

.text-dark {
  color: #727677;
  font-size: 0.8125rem;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}
.fileContainer{
  border-radius: var(--main-system-16-px, 16px);
  border: 1px solid var(--stroke-div-interne, #E6E8EA);
  background: var(--bcg-div-interne, #EEF7FF);
}

.ajoutBtnDoc {
  position: absolute;
  right: 40px;
  top: 5px;
  z-index: 100;
}