.Doublon-row {
  align-items: flex-end;
  background-color: rgba(255, 237, 239, 1);
  border-radius: 6px;
  display: flex;
  height: 31px;
  overflow: hidden;
  padding: 1.5px 39px;
  width: 134px;
}

.rejected {
  letter-spacing: 0px;
  line-height: 13px;
  min-height: 20px;
  min-width: 53px;
  white-space: nowrap;
}

.approved {
  letter-spacing: 0px;
  line-height: 13px;
  min-height: 20px;
  min-width: 63px;
  white-space: nowrap;
}

.Propsition-row {
  align-items: flex-end;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/605d08f588353a18d38d91e8/releases/605d0915c5d15b2edf68cd7e/img/shape-bg-1@1x.png);
  background-size: 100% 100%;
  display: flex;
  height: 31px;
  padding: 1.5px 34px;
  width: 134px;
}

.nunitosans-bold-robins-egg-blue-13px {
  color: rgba(0, 187, 242, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-mandy-13px {
  color: rgba(233, 80, 96, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.Rappel-row {
  align-items: flex-end;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/605d08f588353a18d38d91e8/releases/605d0915c5d15b2edf68cd7e/img/shape-bg@1x.png);
  background-size: 100% 100%;
  display: flex;
  height: 31px;
  padding: 3px 45px;
  width: 134px;
}

.nunitosans-bold-pizazz-12px {
  color: rgba(251, 142, 5, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.pending {
  letter-spacing: 0px;
  line-height: 12px;
  min-height: 18px;
  min-width: 41px;
  white-space: nowrap;
}

.Vente-row {
  align-items: flex-end;
  background-color: rgba(226, 244, 236, 1);
  border-radius: 6px;
  display: flex;
  height: 31px;
  overflow: hidden;
  padding: 1.5px 47px;
  width: 134px;
}

.nunitosans-bold-pine-13px {
  color: rgba(74, 157, 119, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.Affectee-row {
  align-items: flex-end;
  background-color: rgba(252, 193, 86, 0.337);
  border-radius: 6px;
  display: flex;
  height: 31px;
  overflow: hidden;
  padding: 1.5px 9px;
  width: 134px;
}

.planifie-row {
  background-color: rgba(216, 255, 238, 0.337);
  border-radius: 6px;
  display: flex;
  height: 31px;
  overflow: hidden;
  width: 134px;
}

.Planifie-text {
  color: rgba(42, 133, 93, 0.337);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.nunitosans-bold-pizazz-13px {
  color: rgba(251, 142, 5, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.x12 {
  letter-spacing: 0px;
  line-height: 13px;
  min-height: 20px;
  min-width: 113px;
  white-space: nowrap;
}

.nunitosans-bold-gravel-15px {
  color: rgba(71, 71, 71, 1);
   font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.inline_b {
  display: inline-block;
}

.p_relative {
  position: relative;
}

.dropdown-menu {
  top: 60px;
}

.notification-item {
  width: 300px;
  cursor: pointer;
  padding: 13px 1px;
}

.icon {
  position: relative;
  color: rgb(140, 193, 218);
  font-size: 22px;
  cursor: pointer;
  width: 30px;
}

.firstTh {
  border-top-left-radius: 25px;
  padding-right: 0px;
  border-bottom-left-radius: 25px;
}

.lastTh {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom: none;
}

th {
  font-size: 10px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  border: none;
  opacity: 0.8;
}

#tr-h {
  border-top: none;
}

.btn-status {
  background-color: pink;
  font-size: 10px;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 29px;
  padding: 5px;
  cursor: default;
  border: transparent;

}

button[title]:hover:after {
  content: attr(title);

  background-color: dimgrey;
  color: floralwhite;
  border-radius: 6px;
  padding: 5px 20px 5px 5px;
  display: block;
  left: 58rem;
  margin: 10px;
  width: 170px;
  position: absolute;
  top: 155px;
  text-decoration: none;
  font-family: sans-serif;
  z-index: 2;
}

.list-style {
  list-style-type: square;
}

.btn-style {
  border: none;
  background: none;
  padding: 0px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
  color: black;
}

.btn-style:focus {
  background-color: #b8b4b473;
}

.phone-i-BackGround {
  color: #e2f4ec;
  z-index: 0;
  font-size: 30px;
}

.icon-phone-BackGround {
  margin-left: 5px;
  color: #4caf50;
  z-index: 0;
  position: absolute;
  padding-top: 3px;
  padding-left: -2px;
}

.star-i-BackGround {
  z-index: 0;
  padding-left: -2px;
  color: #e7d84f;
  font-size: 30px;
  opacity: 0.3;
  display: none;
}

.img-star {
  z-index: 0;
  position: absolute;
  margin: auto;
  vertical-align: middle;
}

.edit-i-BackGround {
  z-index: 0;
  color: #cccccc;
  opacity: 0.5;
  font-size: 30px;
}

.icon-edit-BackGround {
  margin-left: 4px;
  color: #5f5f5f;
  z-index: 0;
  position: absolute;
  padding: 2px;
}

.icon-file-BackGround {
  margin-left: 5px;
  color: #9b9b9b;
  z-index: 0;
  position: absolute;
  padding: 2px;
}

.file-i-BackGround {
  z-index: 0;
  color: #5f5f5f;
  opacity: 0.4;
  font-size: 30px;
}

.btn-modifier {
  width: 32px;
  height: 27px;
  border: none;
  background-color: #8b868636;
  border-radius: 4px;
  font-weight: 500;
}

.btn-dt-act {
  width: 245px;
  height: 48px;
  background-color: #ff808b;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
}

.btn-dt-save {
  width: 189px;
  height: 48px;
  background-color: #44a2fe;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
}

.btn-dt-clone {
  width: 189px;
  height: 48px;
  background-color: #eff3f8;
  border-color: #ea243f;
  border-radius: 15px;
  color: #ea243f;
  text-align: center;
}



.navbar-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

/* .select-style {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  border: none;
  font-size: 11px;
} */

.select-entree {
  /* border-radius: 5px;
  background-color: white; */
  /* border: none; */
  /* font-size: 10px; */
  /* font-weight: bold; */
  /* border: 1px solid #d9d9d9; */
  /* padding: 2px; */
  /* height: 24px; */
}

.active-page {
  background-color: #2a96ff;
  color: #f7f7f7;
}

.page_input {
  width: 51px;
}

.info-bul {
  width: 311px;
  position: absolute;
  transform: translate3d(283px, 486px, 0px);
  top: 19px;
  left: 0px;
  will-change: transform;
  padding: 15px;
  border-radius: 15px;
  background-color: #8ac5ff;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
  height: 53px;
  overflow: hidden;
}

.enGras {
  font-weight: bold;
}

.content-table thead tr {
  background-color: rgba(244, 250, 255, 1);
  text-align: left;
  font-weight: bold;
  border-bottom: 5px solid white;
  width: 1250px;
  height: 35px;
}

.content-table th {
  width: 1250px;
  height: 53px;
  padding-left: 15px;
}

.content-table td {
  padding: 12px 15px;
  width: 1250px;
  height: 80px;
}

.content-table tbody tr {
  border-bottom: 5px solid white;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: rgba(246, 246, 246, 1);
}

.content-table tbody tr:nth-of-type(odd) {
  background-color: rgba(250, 250, 250, 1);
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #ffffff;
}

/*-----------ouvrir-----------*/

#Ouvrir:hover {
  background-color: rgb(104, 194, 194);
}

#Ouvrir {
  position: relative;
  width: 92px;
  height: 28px;
  margin: 0px 7px;
  background-color: rgba(214, 234, 249, 1);
  overflow: visible;
  border: none;
  border-radius: 8px;
  display: inline-block;
}

.icon-ouvrir {
  overflow: visible;
  position: absolute;
  width: 12.375px;
  height: 12.374px;
  left: 16px;
  top: 8px;
}

/*-----------------------luanch--------------------------------*/

#launch {
  position: absolute;
  width: 12.014px;
  height: 14.358px;
  overflow: visible;
  cursor: pointer;
  border: none;
  margin-left: 40px;
  background-color: rgba(244, 250, 255, 1);
  display: inline-block;
}

.fleche {
  position: absolute;
  width: 14.358px;
  height: 6.163px;
  left: 0px;
  top: 0px;
}

#f1 {
  transform: translate(0px, 0px) matrix(1, 0, 0, 1, -4.0972, 4.0972) rotate(90deg);
}

#f2 {
  transform: translate(0px, 0px) matrix(1, 0, 0, 1, 1.7532, 4.0977) rotate(-90deg);
}

/* -------details------------ */

#details:hover {
  background-color: #808080;
}

#details {
  position: relative;
  width: 28px;
  height: 28px;
  margin: 0px 7px;
  background-color: rgba(204, 204, 204, 0.639);
  overflow: visible;
  border: none;
  border-radius: 7px;
  display: inline-block;
}

.rect-det {
  overflow: visible;
  width: 12.105px;
  height: 13.114px;
  left: 7px;
  top: 8px;
}

.stylo-det {
  overflow: visible;
  position: absolute;
  width: 11.097px;
  height: 11.097px;
  left: 11px;
  top: 7px;
}

.card-gestion {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}



/* Start Nouveau Listing Gestion */

.header-card-gestion {
  background-color: #e2ecf6;
  border-radius: 10px 10px 0px 0px;
}

.rounded-card {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.head-table {
  background-color: #F5FAFF;
  color: var(--NOT-liste-vide, #959494);
  text-align: center;
  font-family:'Nunito Sans', sans-serif;;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table thead th {
  border-bottom: none !important;
}

.body-table tr td {
  text-align: center;
}

table tr th:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.table thead th {
  border: none;
}

table tr th:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.table td {
  border: transparent 2px solid;
  vertical-align: inherit;
}

.table th {
  font-size: 13px;
  border: transparent 2px solid;
}

table.dataTable > thead > tr > th:not(.sorting_disabled),
table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding-left: 30px;
}

/* End Nouveau Listing Gestion */

/* Start style DataTable */

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: flex-start;
}

.page-item.previous .page-link {
  border: none;
}

.page-item.next .page-link {
  border: none;
}

.page-item.disabled .page-link {
  border: none;
}

.page-item {
  cursor: pointer;
  font-size: 10px;
  margin-left: 3px;
  margin-right: 3px;
}

.page-link {
  cursor: pointer;
  font-size: 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
}

.pagination {
  justify-content: center;
}

.page-link-symbol,
.page-link-symbol-chevron {
  position: relative;
  display: block;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
}

.page-link-symbol-chevron {
  padding-top: 17px;
}

.page-link-symbol {
  padding-top: 6px;
  font-size: 25px;
}

div.dataTables_wrapper div.dataTables_filter input {
  height: 36px;
  width: 314px;
  border-radius: 50px;
  margin-top: -50px;
}

.dataTables_length {
  margin-top: 5px;
}

.page-link {
  border: 1px solid #dee2e6 !important;
}

/* End style DataTable */

.row-info {
  margin: 2% 6% 2% 4%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.card-info-1 {
  width: 17%;

  border-radius: 16px;
  background-color: #E3E3E3;
  color: #66696A;
  text-align: center;
  cursor: pointer;
  border: 1px solid #CECECE;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

.card-info-1:hover{
  border: 1px solid var(--NOT-liste-vide, #959494);
color: #212529;
}

.card-info-1:active{
  border-radius: var(--Main-System-16px, 16px);
color: #FFFFFF;
  border: 1px solid var(--color-texte, #212529);
  background: var(--color-texte, #212529);
}

.card-info-2 {
  width: 17%;
  height: 4;
  border-radius: var(--Main-System-16px, 16px);
  border: 1px solid #6BD388;
  color: #6BD388;
  background: #E5FFED;
  cursor: pointer;
}
.card-info-2:hover{
  border-radius: var(--Main-System-16px, 16px);
color: #31B255;
  border: 1px solid var(--configurer-Activer-switch-OUI---chek, #31B255);
  background: var(--vert-hover, #D6F0DD);
}
.card-info-2:active{
  border-radius: var(--Main-System-16px, 16px);
color: #fff;
  border: 1px solid #31B255;
  
  background: #31B255;
}

.card-info-3 {
  width: 17%;
  height: 4;
  border-radius: var(--Main-System-16px, 16px);
color: #FFA739;
  border: 1px solid #FFD299;
  
  background: var(--avenant-bcg, #FEE8CD);

  cursor: pointer;
}
.card-info-3:hover {
  border-radius: var(--Main-System-16px, 16px);
color: #FB8E05;
border: 1px solid var(--avenant-2, #FDBB69);
background: var(--avenant-bcg, #FEE8CD);
}
.card-info-3:active{
  border-radius: var(--Main-System-16px, 16px);
border: 1px solid var(--avenant-OU-info-avenant-info, #FB8E05);
background: var(--avenant-OU-info-avenant-info, #FB8E05);
color: #fff;
}

.card-info-4 {
  width: 3rem;
  width: 17%;
  height: 4;
  border-radius: var(--Main-System-16px, 16px);
  border: 1px solid #FCCDD1;
  background: var(--secondary-color-3-bcg, #FFECEE);
  color: #FC9CA5;
  cursor: pointer;
}
.card-info-4:hover{
  border-radius: var(--Main-System-16px, 16px);
  border: 1px solid var(--secondary-color, #FF808B);
  background: var(--secondary-color-3-bcg, #FFECEE);
  color: #FF808B;
}
.card-info-4:active{
  border-radius: var(--Main-System-16px, 16px);
  border: 1px solid var(--secondary-color, #FF808B);
  background: var(--secondary-color, #FF808B);
  color: #fff;
}
.card-info-5{
  width: 3rem;
  width: 17%;
  height: 4;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  border-radius: var(--Main-System-16px, 16px);
border: 1px solid #C3E2FF;
background: var(--color-principal-2---bcg-bouton, #D4EAFF);
color: #2A96FF;
}
.card-info-5:hover{
  border-radius: var(--Main-System-16px, 16px);
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#0074E4 ;
}
.card-info-5:active{
  border-radius: var(--Main-System-16px, 16px);
border: 1px solid var(--principal-color, #2A96FF);
background: var(--principal-color, #2A96FF);
color: #fff;
}


.p-info-1 {
  margin: 20px;
  margin-bottom: 10px;
  font-size: xx-large;

  font-family: "Nunito Sans", sans-serif  !important;
}

.p-info-2 {
  font-size: medium;
}

.noData{
  color: #959494;
  padding-top: 10px;
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}