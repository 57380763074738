// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$geoprod-Front-primary: mat-palette($mat-indigo);
$geoprod-Front-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$geoprod-Front-warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$geoprod-Front-theme: mat-light-theme(( color: ( primary: $geoprod-Front-primary, accent: $geoprod-Front-accent, warn: $geoprod-Front-warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($geoprod-Front-theme);
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$geoprod-Front-primary: mat-palette($mat-indigo);
$geoprod-Front-accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).
$geoprod-Front-warn: mat-palette($mat-red);

$custom-typography: mat-typography-config(
  $font-family: 'Nunito Sans, sans-serif'
);

/* Apply the custom typography */
@include angular-material-typography($custom-typography);