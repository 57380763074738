

/* -----------------------------  Calendrier  ------------------------------- */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

/* --------------------------------------------------------------------------- */


/* -------------------------  Agenda ------------------------------------------- */

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';

/* ---------------------------------------------------------------------------- */

@import '~bootstrap/dist/css/bootstrap.css';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~jsoneditor/dist/jsoneditor.min.css';

/* -----------------------------Custom Geoprod Templates----------------------- */

@import './app/template/buttons/buttons.component.css';
@import '../src/app/template/data-table/data-table.component.css';
@import '../src/app/template/form-fields/form-fields.component.css';
@import '../src/app/template/inputs/inputs.component.css';

@import url('https://font-awesome.neopolis-dev.com/font-awesome-pro@main/fontawesome-pro-6.5.1-web/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap');


/* ---------------------------------------------------------------------------- */

*:focus {
    /* border: none; */
    outline: none;
}

button:focus {
    border: none;
    outline: none;
}



@font-face {
    font-family: "Nunito Sans", sans-serif;
    src: local('Nunito Sans'), url('../src/assets/font/nunito-sans/NunitoSans_10pt-Regular.ttf') ;
    font-weight: 400; 
    font-style: normal;
  }

  @font-face {
    font-family:  'Nunito Sans', sans-serif;
    src: local('Nunito Sans Semibold'), url( '../src/assets/font/nunito-sans/NunitoSans_10pt-SemiBold.ttf') ;
    font-weight: 700; 
    font-style: normal;
  }
@font-face {
    font-family: 'Poppins Bold';
    src: local('Poppins Bold'), url('./assets/font/Poppins-Bold.ttf');
}

html,
body {
    font-family:'Nunito Sans', sans-serif;
    height: 100%;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

.mat-radio-button {
    display: block !important;
}

.col {
    padding-left: 0px;
    padding-right: 0px;
    transition: max-width 1s;
}

.page-item.active .page-link {
    z-index: 1;
}

.autocomplete-container .input-container input {
    display: block !important;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.autocomplete-container .input-container input {
    display: block !important;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.autocomplete-container .input-container .x i {
    font-family: 'Material Icons', sans-serif !important;
}

.autocomplete-container {
    box-shadow: 0 0 0 0 !important;
}

.timepicker-form-field {
    display: inline-block;
    width: 90px;
}

.timepicker-form-field-icon {
    display: inline-block;
    width: 35px;
}

.send-btn:hover {
    color: white;
}

.send-btn {
    min-width: 130px;
    min-height: 43px;
    border-radius: 25px;
    border: none;
    background-color: #2a96ff;
    color: white;
}

.next-prev-btn:hover {
    color: white;
}

.next-prev-btn {
    width: 44px;
    min-height: 44px;
    border-radius: 22px;
    border: none;
    background-color: #2a96ff;
    color: white;
    margin: 5px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family:'Nunito Sans', sans-serif;
}

html,
body {
    height: 100%;
    background-color: rgba(239, 243, 248, 1);
}

body {
    margin: 0;
    font-family:'Nunito Sans', sans-serif;
}

* {
    font-family:'Nunito Sans', sans-serif;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 99999 !important;
}

.cdk-overlay-connected-position-bounding-box {
    z-index: 100000 !important;
}

.prospect-card-field {
    min-width: 200px;
}

.font-primary-geoprod {
    color: #2a96ff;
    font-size: 15px !important;
    font-weight: 900;
    font-family:'Nunito Sans', sans-serif;
    padding-top: 13px;
}

.expension-secondary-color {
    background-color: #edf5fd;
    border-radius: 5px;
}

.btn-action-primary {
    display: inline-block !important;
    margin: 5px 5px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2a96ff;
}

::ng-deep .swal2-height-auto {
    height: auto !important;
}

.cover {
    pointer-events: none;
    opacity: 0.5;
}

.noData{
    color: #959494;
    padding-top: 10px;
    text-align: center;
    font-family:'Nunito Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.modal-content{
    border-radius: 16px;
}

#sentry-feedback {
    --bottom :40px
  }
  
  