@charset "UTF-8";

/*
* CSS TOGGLE SWITCH
*
* Ionuț Colceriu - ghinda.net
* https://github.com/ghinda/css-toggle-switch
*
*/

/* supported values are px, rem-calc, em-calc
 */

/* imports
 */

/* Functions
 */

/* Shared
 */

/* Hide by default
 */

.switch-toggle a,
.switch-light span span {
  display: none;
}

/* .col-4{
  padding: 10px;
} */

/* We can't test for a specific feature,
 * so we only target browsers with support for media queries.
 */

@media only screen {
  /* Checkbox
 */
  .switch-light {
    position: relative;
    display: block;
    /* simulate default browser focus outlines on the switch,
   * when the inputs are focused.
   */
  }
  .switch-light::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-light *,
  .switch-light *:before,
  .switch-light *:after {
    box-sizing: border-box;
  }
  .switch-light a {
    display: block;
    transition: all 0.2s ease-out;
  }
  .switch-light label,
  .switch-light > span {
    /* breathing room for bootstrap/foundation classes.
     */
    line-height: 2em;
  }
  .switch-light input:focus ~ span a,
  .switch-light input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
     */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-light input:focus ~ span a,
  .switch-light input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@media only screen {
  /* don't hide the input from screen-readers and keyboard access
 */
  .switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 3;
  }
  .switch-light input:checked ~ span a {
    right: 0%;
  }
  /* inherit from label
 */
  .switch-light strong {
    font-weight: inherit;
  }
  .switch-light > span {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 2em;
    /* overwrite 3rd party classes padding
   * eg. bootstrap .alert
   */
    padding: 0;
    text-align: left;
  }
  .switch-light span span {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    user-select: none;
  }
  .switch-light a {
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
  }
  /* bootstrap 4 tweaks
*/
  .switch-light.row {
    display: flex;
  }
  .switch-light .alert-light {
    color: #333;
  }
  /* Radio Switch
 */
  .switch-toggle {
    position: relative;
    display: block;
    /* simulate default browser focus outlines on the switch,
   * when the inputs are focused.
   */
    /* For callout panels in foundation
  */
    padding: 0 !important;
    /* 2 items
   */
    /* 3 items
   */
    /* 4 items
   */
    /* 5 items
   */
    /* 6 items
   */
  }
  .switch-toggle::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-toggle *,
  .switch-toggle *:before,
  .switch-toggle *:after {
    box-sizing: border-box;
  }
  .switch-toggle a {
    display: block;
    transition: all 0.2s ease-out;
  }
  .switch-toggle label,
  .switch-toggle > span {
    /* breathing room for bootstrap/foundation classes.
     */
    line-height: 2em;
  }
  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
     */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@media only screen {
  .switch-toggle input {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  .switch-toggle input + label {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    padding: 0 0.5em;
    margin: 0;
    text-align: center;
  }
  .switch-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(4),
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ a {
    width: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ input:checked:nth-child(3) + label ~ a {
    left: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6),
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ a {
    width: 33.33%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(3) + label ~ a {
    left: 33.33%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(5) + label ~ a {
    left: 66.66%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8),
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ a {
    width: 25%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(3) + label ~ a {
    left: 25%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(5) + label ~ a {
    left: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(7) + label ~ a {
    left: 75%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10),
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ a {
    width: 20%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(3) + label ~ a {
    left: 20%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(5) + label ~ a {
    left: 40%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(7) + label ~ a {
    left: 60%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(9) + label ~ a {
    left: 80%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12),
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ a {
    width: 16.6%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(3) + label ~ a {
    left: 16.6%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(5) + label ~ a {
    left: 33.2%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(7) + label ~ a {
    left: 49.8%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(9) + label ~ a {
    left: 66.4%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(11) + label ~ a {
    left: 83%;
  }
  /* Candy Theme
 * Based on the "Sort Switches / Toggles (PSD)" by Ormal Clarck
 * http://www.premiumpixels.com/freebies/sort-switches-toggles-psd/
 */
  .switch-toggle.switch-candy,
  .switch-light.switch-candy > span {
    background-color: #818181;
    border-radius: 3px;
    box-shadow: inset 0 6px 6px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.2);
  }
  .switch-light.switch-candy span span,
  .switch-light.switch-candy input:checked ~ span span:first-child,
  .switch-toggle.switch-candy label {
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px #191b1e;
  }
  .switch-light.switch-candy input ~ span span:first-child,
  .switch-light.switch-candy input:checked ~ span span:nth-child(2),
  .switch-candy input:checked + label {
    color: #fff;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
  .switch-candy a {
    border: 1px solid #575757;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.45);
    background-color: #2a96ff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
  }
  .switch-candy-blue a {
    background-color: #2a96ff;
  }
  .switch-candy-yellow a {
    background-color: #2a96ff;
  }
  /* iOS Theme
*/
  .switch-ios.switch-light span span {
    color: red;
  }
  .switch-ios.switch-light a {
    left: 0;
    top: 0;
    width: 2em;
    height: 2em;
    background-color: #fff;
    border-radius: 100%;
    border: 0.25em solid #d8d9db;
    transition: all 0.2s ease-out;
  }
  .switch-ios.switch-light > span {
    display: block;
    width: 100%;
    height: 2em;
    background-color: #d8d9db;
    border-radius: 1.75em;
    transition: all 0.4s ease-out;
  }
  .switch-ios.switch-light > span span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    line-height: 1.875em;
    vertical-align: middle;
    transition: all 0.2s ease-out;
  }
  .switch-ios.switch-light > span span:first-of-type {
    opacity: 1;
    padding-left: 1.875em;
  }
  .switch-ios.switch-light > span span:last-of-type {
    padding-right: 1.875em;
  }
  .switch-ios.switch-light input:checked ~ span a {
    left: 100%;
    border-color: #2a96ff;
    margin-left: -2em;
  }
  .switch-ios.switch-light input:checked ~ span {
    border-color: #2a96ff;
    box-shadow: inset 0 0 0 30px #2a96ff;
  }
  .switch-ios.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .switch-ios.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  .switch-ios.switch-toggle {
    /* background-color: #D8D9DB; */
    border-radius: 30px;
    border: 1px solid #d3d3d3;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
  }
  .switch-ios.switch-toggle a {
    background-color: #36a3f7;
    border: 0.125em solid #d8d9db;
    border-radius: 1.75em;
    transition: all 0.12s ease-out;
  }
  .switch-ios.switch-toggle label {
    height: 2.4em;
    color: #898989;
    line-height: 2.4em;
    vertical-align: middle;
    width: 33.33%;
  }
  .switch-ios input:checked + label {
    color: #fff;
    font-weight: bold;
    background: #44a2fe;
    border-radius: 21px;
  }
  /* Holo Theme
 */
  .switch-toggle.switch-holo,
  .switch-light.switch-holo > span {
    background-color: #464747;
    border-radius: 1px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    color: #fff;
    text-transform: uppercase;
  }
  .switch-holo label {
    color: #fff;
  }
  .switch-holo > span span {
    opacity: 0;
    transition: all 0.1s;
  }
  .switch-holo > span span:first-of-type {
    opacity: 1;
  }
  .switch-holo > span span,
  .switch-holo label {
    font-size: 85%;
    line-height: 2.15625em;
  }
  .switch-holo a {
    background-color: #666;
    border-radius: 1px;
    box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.3) 0 -1px 0;
  }
  /* Selected ON switch-light
*/
  .switch-holo.switch-light input:checked ~ span a {
    background-color: #2a96ff;
  }
  .switch-holo.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .switch-holo.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
  }
  /* Material Theme
 */
  /* switch-light
 */
  .switch-light.switch-material a {
    top: -0.1875em;
    width: 1.75em;
    height: 1.75em;
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2),
      0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
    transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .switch-material.switch-light {
    overflow: visible;
  }
  .switch-material.switch-light::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-material.switch-light > span {
    overflow: visible;
    position: relative;
    top: 0.1875em;
    width: 3.25em;
    height: 1.5em;
    min-height: auto;
    border-radius: 1em;
    background: rgba(0, 0, 0, 0.26);
  }
  .switch-material.switch-light span span {
    position: absolute;
    clip: rect(0 0 0 0);
  }
  .switch-material.switch-light input:checked ~ span a {
    right: 0;
    background: #3f51b5;
    box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2),
      0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
  }
  .switch-material.switch-light input:checked ~ span {
    background: rgba(63, 81, 181, 0.5);
  }
  /* switch-toggle
 */
  .switch-toggle.switch-material {
    overflow: visible;
  }
  .switch-toggle.switch-material::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-toggle.switch-material a {
    top: 48%;
    width: 0.375em !important;
    height: 0.375em;
    margin-left: 0.25em;
    background: #3f51b5;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in;
  }
  .switch-toggle.switch-material label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1em;
  }
  .switch-toggle.switch-material label:before {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    display: block;
    width: 0.875em;
    height: 0.875em;
    border-radius: 100%;
    border: 0.125em solid rgba(0, 0, 0, 0.54);
    transform: translateY(-50%);
  }
  .switch-toggle.switch-material input:checked + label:before {
    border-color: #3f51b5;
  }
  /* ripple
 */
  .switch-light.switch-material > span:before,
  .switch-light.switch-material > span:after,
  .switch-toggle.switch-material label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background: #3f51b5;
    opacity: 0.4;
    margin-left: -1.25em;
    margin-top: -1.25em;
    transform: scale(0);
    transition: opacity 0.4s ease-in;
  }
  .switch-light.switch-material > span:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -1.25em;
  }
  .switch-toggle.switch-material label:after {
    width: 3.25em;
    height: 3.25em;
    margin-top: -0.75em;
  }
  @keyframes materialRipple {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  .switch-material.switch-light input:not(:checked) ~ span:after,
  .switch-material.switch-light input:checked ~ span:before,
  .switch-toggle.switch-material input:checked + label:after {
    animation: materialRipple 0.4s ease-in;
  }
  /* trick to prevent the default checked ripple animation from showing
 * when the page loads.
 * the ripples are hidden by default, and shown only when the input is focused.
 */
  .switch-light.switch-material.switch-light input ~ span:before,
  .switch-light.switch-material.switch-light input ~ span:after,
  .switch-material.switch-toggle input + label:after {
    visibility: hidden;
  }
  .switch-light.switch-material.switch-light input:focus:checked ~ span:before,
  .switch-light.switch-material.switch-light input:focus:not(:checked) ~ span:after,
  .switch-material.switch-toggle input:focus:checked + label:after {
    visibility: visible;
  }
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from
 * http://css-tricks.com/webkit-sibling-bug/
 */

@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light,
  .switch-toggle {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}

@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.switch-block {
  width: 300px;
}

.switch-toggle input {
  width: 0px;
  visibility: hidden;
}

.test-button {
  background-color: green;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background: #eee;
}

section {
  padding: 0px 0;
  min-height: 100px;
}

/*=====================*/

.checkbox-model3 .checkbox {
  position: relative;
  display: inline-block;
  width: 280px;
}

.checkbox-model3 .checkbox:after,
.checkbox-model3 .checkbox:before {
   font-family: 'Nunito Sans', sans-serif;
  font-feature-settings: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}

.checkbox-model3 .checkbox label {
  width: 180px;
  height: 42px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
}

.checkbox-model3 .checkbox label:after {
  content: '';
  position: absolute;
  width: 250px;
  height: 50px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.checkbox-model3 .checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.checkbox-model3 .checkbox input:hover + label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}

.checkbox-model3 .checkbox input:checked + label:after {
  left: 40px;
}

.checkbox-model3 .model-13 .checkbox:after {
  text-align: center;
  content: attr(data-label-off);
   font-family: 'Nunito Sans', sans-serif;
  position: absolute;
  color: #666;
  top: 10px;
  right: 125px;
}

.checkbox-model3 .model-13 .checkbox label {
  background: none;
  border: 3px solid #e8e8e8;
  height: 40px;
  border-radius: 20px;
}

.checkbox-model3 .model-13 .checkbox label:after {
  text-align: center;
  content: attr(data-label-on);
   font-family: 'Nunito Sans', sans-serif;
  color: #fff;
  line-height: 28px;
  text-indent: 100px;
  background: #e8e8e8;
  overflow: hidden;
  box-shadow: none;
  border-radius: 14px;
  transform: translateX(-140px);
  -moz-transition: all 0.4s 0.2s, width 0.2s linear, text-indent 0.4s linear;
  -o-transition: all 0.4s 0.2s, width 0.2s linear, text-indent 0.4s linear;
  -webkit-transition: all 0.4s, width 0.2s linear, text-indent 0.4s linear;
  -webkit-transition-delay: 0.2s, 0s, 0s;
  transition: all 0.4s 0.2s, width 0.2s linear, text-indent 0.4s linear;
  top: 3px;
  left: auto;
  right: 2px;
  width: 28px;
  height: 28px;
}

.checkbox-model3 .model-13 .checkbox input:checked + label {
  border-color: #2a96ff;
}

.checkbox-model3 .model-13 .checkbox input:checked + label:after {
  background: #2a96ff;
  left: auto;
  transform: translateX(0px);
  -moz-transition: all 0.4s, width 0.2s linear 0.4s, text-indent 0.3s linear 0.4s;
  -o-transition: all 0.4s, width 0.2s linear 0.4s, text-indent 0.3s linear 0.4s;
  -webkit-transition: all 0.4s, width 0.2s linear, text-indent 0.3s linear;
  -webkit-transition-delay: 0s, 0.4s, 0.4s;
  transition: all 0.4s, width 0.2s linear 0.4s, text-indent 0.3s linear 0.4s;
  width: 170px;
  text-indent: 0;
}

.btn-dt-enregistrer {
  margin: 0 5px;
  width: 222px;
  height: 52px;
  border: none;
  background-color: rgba(42, 150, 255, 1);
  border-radius: 10px;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.btn-suivant {
  border-radius: 6px;
  border: 1px solid #6197cb;
  background: #e4f0ff;
  width: 122px;
  height: 38px;
  color: #6197cb;
  fill: #6197cb;
  font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
font-family: 'Nunito Sans', sans-serif;;
}
.btn-suivant:hover{
color: #195A99;
border: 1px solid #195A99;
fill: #195A99;
}
.btn-suivant:active{
  color: white;
border: 1px solid #6197CB;
background-color: #6197CB;
fill: white;
}

.Avenant-btn {
  width: 198px;
  height: 48px;
  background-color: #FEE8CD;
  border-radius: 15px;
  font-weight: bolder;
  color: #FB8E05;
  font-size: 14px;
  border: 1px solid #FED4A1;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

.Avenant-btn:hover{
  background-color: #FDBB69;
  color:#FFFFFF;
  border: none;
}
.Avenant-btn:active{
  background-color: #FB8E05;
  color:#FFFFFF;
  border: none;
}

.design_icon_enrg {
  width: 23.076px;
  height: 23.076px;
  margin: 0px 20px;
}

#design_icon_enrg {
  fill: rgba(255, 255, 255, 1);
}

.icon-btn-retour {
  margin-right: 10px;
}

.btn-dt-retour {
  /* Button retour à la liste*/
  display: block;
  background-color: #6197cb;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  color: white;
  font-weight: bolder;
  width: 180px;
  height: 45px;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  text-align: center;
}

.btn-dt-retour span,
.btn-dt-retour .icon-position {
  display: block;
  text-align: center;
  position: absolute;
  margin-left: 0px;
}

.btn-dt-retour span {
  line-height: inherit;
  font-size: 14px;
  margin-left: 42px;
  margin-top: -2px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-retour .icon-position {
  width: 44px;
  margin-top: -9px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-retour .icon-position .fa {
  font-size: 20px;
  vertical-align: middle;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.btn-dt-retour.success span,
.btn-dt-retour:hover span {
  left: -72%;
  opacity: 0;
  display: none;
}

.btn-dt-retour.success .icon-position,
.btn-dt-retour:hover .icon-position {
  width: 180px;
}

.btn-dt-retour:hover {
  opacity: 0.9;
}

/* Custom button retour à la liste dans les détails*/

.btn-custom-details {
  width: 234px;
  height: 48px;
}

.btn-custom-details span {
  margin-left: 66px;
}

.btn-custom-details .icon-position {
  width: 75px;
}

.btn-custom-details:hover .icon-position {
  width: 217px;
}

.btn-dt-details {
  /* Button details à la liste*/
  display: block;
  background-color: #2a96ff;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  color: white;
  font-weight: bolder;
  width: 180px;
  height: 45px;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  text-align: center;
}


.btn-dt-details:hover{
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-details:active{
  background: var(--princip-color-2, #0074E4);
  color:#FFF
}

.btn-dt-details span,
.btn-dt-details .icon-position {
  display: block;
  text-align: center;
  position: absolute;
  margin-left: 0px;
}

.btn-dt-details span {
  line-height: inherit;
  font-size: 14px;
  margin-left: 42px;
  margin-top: -2px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-details .icon-position {
  width: 44px;
  margin-top: -9px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-details .icon-position .fa {
  font-size: 20px;
  vertical-align: middle;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.btn-dt-details.success span,
.btn-dt-details:hover span {
  left: -72%;
  opacity: 0;
  display: none;
}

.btn-dt-details.success .icon-position,
.btn-dt-details:hover .icon-position {
  width: 180px;
}
/*
.btn-dt-details:hover .icon-btn-add{
  color:#2A96FF
}
.btn-dt-details:hover .icon{
  color:#2A96FF;
}
.btn-dt-details:hover .iconAff{
  transform: translatex(-10%);
}
.btn-dt-details:active .icon-btn-add{
  color:#fff
}
.btn-dt-details:active .icon{
  color:#fff;
}

.icon{
  color:#fff
} */


.design_icon_retour {
  width: 22px;
  height: 10.656px;
  transform: translate(-479.77px, -481px) matrix(1, 0, 0, 1, 479.7695, 487.4997) rotate(180deg);
  margin: 0px 20px;
}

#design_icon_retour {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 1px;
  stroke-linejoin: bevel;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.btn-dt-desactiver {
  /* Button déactiver*/
  margin: 0 5px;
  width: 103px;
  height: 28px;

  background-color: #FFECEE;
  border: 1px solid #E13636;
  border-radius: 10px;
  color: #E13636;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.btn-dt-desactiver:hover {
  background-color: #FFAAAA;
  border: 1px solid #E13636;
  color: #E13636;
}
.btn-dt-desactiver:active {
  background-color: #E13636;
  border:none;
  color:#FFF;
}



.btn-dt-ajouter {
  width: 134px;
  height: 43px;
  background-color: #2a96ff;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  display: block;
  justify-content: center;
  align-items: center;
}

.btn-dt-ajouter:hover {
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-ajouter:active {
  background: var(--princip-color-2, #0074E4);
  color:#FFF
}


.btn-dt-fermer {
  /* Button cancel popup*/
  width: 134px;
  height: 43px;
  background-color: #dedfdf;
  border: none;
  border-radius: 15px;
  color: #212529;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
  display:flex;
  justify-content: center;
align-items: center;
}
.btn-dt-fermer:hover {
  background-color: var(--Annuler-ou-cursor, #959494);
  color: #FFF;
}
.btn-dt-fermer:active {
  background-color: var(--princip-color-2, #66696A);
  color:#FFF
}


.icon-ouvrir {
  overflow: visible;
  position: absolute;
  width: 12.375px;
  height: 12.374px;
  left: 16px;
  top: 8px;
}

.btn-dt-activer {
  /* Button Activer*/
  width: 103px;
  height: 28px;
  padding-left: 3px;
  background-color: #E5FFED;
  border-radius: 10px;
  color: #16C046;
  border: 1px solid #16C046;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.btn-dt-activer:hover {
  background-color: #D6F0DD;
  color: #16C046;
  border: 1px solid #16C046;
}
.btn-dt-activer:active {
  background-color: #16C046;
  border:none;
  color:#FFF;
}


.btn-dt-ouvrir {
  /* Button Ouvrir*/
  width: 92px;
  height: 28px;
  border: none;
  background-color: #d6eaf9;
  border-radius: 10px;
  color: #0882de;
  font-weight: bold;
  font-size: 12px;
}
.btn-dt-ouvrir:hover{
  border: 1px solid var(--principal-color, #2A96FF);

}
.btn-dt-ouvrir:active{
  background: var(--princip-color-2, #0074E4);
  color:#FFF;
  border: none;

}

.icon-btn-ouvrir {

  margin-left: 5px;
}

.test-button {
  background-color: green;
}

.rectangleIcondeletes {
  width: 14.666px;
  height: 17.954px;
}

#svgdeletes {
  fill: rgba(255, 255, 255, 1);
}

.check_design {
  font-size: 30px;
  color: rgba(76, 175, 80, 1);
  text-align: center;
  margin: 0 15px;
}

.cancel_design {
  font-size: 30px;
  color: rgba(230, 3, 3, 1);
  text-align: center;
  margin: 0 15px;
}

.design_btn-deletez {
  width: 18px;
  height: 22.036px;
  margin: 0 15px;
}

#design_btn-deletez {
  fill: rgba(212, 6, 6, 1);
}

.btn-refrech {
  margin: 0 15px;
  width: 29px;
  height: 29px;
  background-color: white;
  border-radius: 10px;
  font-weight: 500;
  color: rgba(42, 150, 255, 1);
  border: 2px solid rgba(42, 150, 255, 1);
  /* Green */
  padding: 6px 0px;
  cursor: pointer;
  margin-right: 2px;
  text-align: center;
}

.btn-load {
  border: 1.6px solid #2a96ff;
  background-color: transparent;
  width: 42px;
  height: 42px;
  color: #2a96ff;
  font-size: 18px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  margin-right: 15px;
  margin-top: 5px;
  margin-left: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

}
/* .btn-load:hover{
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  opacity: 0.5;

}
.btn-load:active ,.btn-load-actu:active{
background-color: #D4EAFF;

} */

.btn-save {
  border: 1.6px solid #fb8e05;
  background-color: white;
  width: 42px;
  height: 42px;
  color: #fb8e05;
  font-size: 18px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  margin-right: 15px;
  margin-top: 5px;
  margin-left: 5px;
}

.btn-save:focus {
  color: white;
  background-color: #fb8e05;
}

.btn-load-actu {
  border: 1.6px solid #2a96ff;
  background-color: transparent;
  width: 42px;
  height: 42px;
  color: #2a96ff;
  font-size: 18px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.btn-load-actu:hover,
.btn-load:hover {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.btn-load-actu:focus,
.btn-load:focus {
  display: none;
}

.btn-dt-ouvrir mat-icon {
  font-size: 15px;
  color: rgba(8, 130, 222, 1);
  margin-right: 2px;
  text-align: center;
}

.btn-dt-activer mat-icon {
  /* icon Button Activer*/
  font-size: 15px;
  color: white;
  margin-right: 2px;
  text-align: center;
}

.btn-dt-desactiver mat-icon {
  /* icon  déactiver*/
  font-size: 15px;
  color: white;
  margin-right: 2px;
  text-align: center;
}

.btn-dt-print mat-icon {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-right: 2px;
  text-align: center;
}
.filtreFavoris{
  display: flex;
  color: #FFF;
width: 196px;
height: 38px;
padding: 0px 6.601px 0px 5.601px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 15px;
background: var(--avenant-ou-info-avenant-info, #FB8E05);
border:none;
/* principal shadow */
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.filtrer{
  border-radius: 15px;
background: var(--principal-color, #2A96FF);
/* principal shadow */
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
border:none;
font-family: 'Nunito Sans', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 104px;
height: 38px;
justify-content: center;
align-items: center;

}
.filtrer:hover , .filtrer:active{
  background: #0074E4;

}

.btn-dt-print {
  margin: 0 5px;
  width: 132px;
  height: 40px;
  border: none;
  background-color: #6197cb;
  border-radius: 3px;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: bolder;
}
.btn-dt-actions-groupee {
  margin: 0 5px;
  width: 152px;
  height: 40px;
  border: none;
  border-radius: 15px;
  background: var(--configurer-activer-switch-oui-chek, #31B255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;

font-family: 'Nunito Sans', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
  padding: 6px 6px;
  cursor: pointer;

}

.btn-dt-actions-groupee:hover , .btn-dt-actions-groupee:active{
  background: #0A7C2A

}

.btn-dt-actions-groupee:disabled{
  color: var(--NOT-liste-vide, #959494);
  border-radius: 15px;
  border: 1px solid var(--Gris-1, #E3E3E3);
  background: var(--Gris-1, #E3E3E3);
  box-shadow:none
}

.btn-dt-exporter mat-icon {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-right: 2px;
  text-align: center;
}


.btn-dt-exporter {
  margin: 0 5px;
  width: 149px;
  height: 40px;
  background-color: #fff;
  border-radius: 15px;
  padding: 6px 0px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: bolder;
  border: 0.5px solid #BFDFFD;
  box-shadow: 0px 0px 6px 0px #2A96FF3B;
  color: #2A96FF;
}

.btn-dt-exporter:hover{
  background-color: #D4EAFF;
  box-shadow: 0px 2px 6px 0px #0000002E;
  
}


.btn-dt-print {
  margin: 0 5px;
  width: 132px;
  height: 40px;
  border: none;
  background-color: #6197cb;
  border-radius: 3px;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(153, 221, 113, 1);
}

::ng-deep .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
}

::ng-deep .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(214, 43, 45, 1);
}

.example-small-box,
.example-large-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
}

.example-small-box {
  height: 100px;
  width: 100px;
}

.example-large-box {
  height: 300px;
  width: 300px;
}

::ng-deep .mat-tab-label .mat-tab-label-content {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(42, 150, 255, 1);
}

::ng-deep .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: rgba(42, 150, 255, 1);
}

.filter-bubble-icon {
  color: white;
  font-size: 20px;

  cursor: pointer;
}

.filter-bubble-icon2 {
  color: #2a96ff;
  font-size: 22px;
  top: 42px;
  left: 65px;
  width: fit-content;
  display: inline-block;
  margin-right: 10px;
}

.filter-bubble {
  width: 45px;
  height: 45px;
  background-color: #418eff;
  position: fixed;
  right: 12px;
  top: 184px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  align-items: center;
  display: flex;
  justify-content: center;
}
/*
.filter-bubble-icon:hover {
  font-size: 22px;
  top: 7px;
  left: 16px;
} */

.filter-bubble-icon2 {
  color: #2a96ff;
  font-size: 22px;
  top: 42px;
  left: 65px;
  width: fit-content;
  display: inline-block;
  margin-right: 10px;
}

.add-btn:hover {
  color: white;
}

.add-btn-search {
  width: 150px;
  height: 35px;
  border-radius: 15px;
  border: none;
  background-color: #2a96ff;
  color: white;
  font-size: 12px;
  font-weight: bolder;
}

.add-btn-em {
  min-height: 42px;
  font-family: 'Nunito Sans', sans-serif;
  border: none;
  background-color: #2a96ff;
  color: white;
  font-size: 13px;
  border-radius: 15px;
  font-weight: 700;
line-height: normal;
box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
}
.add-btn-em:hover{
color :#2A96FF;
background-color: #D4EAFF;
border: 1px solid var(--principal-color, #2A96FF);

}
.add-btn-em:active{
background-color: #0074E4;
color: #fff;
}

.fa-icon-add {
  width: 24px;
}

.filtre-spn .fa-lg,
.FILTRES__RECHERCHES .fa-2x {
  cursor: pointer;
  color: rgba(42, 150, 255, 1);
}

.FILTRES__RECHERCHES {
  overflow: visible;
  text-align: left;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(42, 150, 255, 1);
}

.filtre-spn span {
  white-space: nowrap;
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(42, 150, 255, 1);
  display: inline-block;
  margin: 2px 2px;
}

.add-btn-en {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  align-content: center;
  border-radius: 15px;
  border: none;
  background-color: #2a96ff;
  color: white;
  padding: 4.5px;
  cursor: pointer;
}

.filter-close-corp-bubble {
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: #2a96ff;
  border-radius: 50%;
  left: -16px;
  top: -10px;
  cursor: pointer;
}

.close-element {
  margin: auto;
  width: fit-content;
  margin-top: 6px;
  font-size: 20px;
  color: white;
  transform: rotate(45deg);
}

.close-element:hover {
  font-size: 22px;
  margin-top: 5px;
}

.scrolling {
  overflow-y: scroll;
  position: relative;
  height: 53vh;
}

.add-bg-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 24px;
  padding-top: 2.5px;
  box-sizing: border-box;
  color: #2a96ff;
  cursor: pointer;
}

.add-bg-btn fa-icon {
  cursor: pointer;
  font-size: 16px;
}

.add:hover {
  color: white;
}

form.example input[type='text'] {
  padding: 10px;
  font-size: 17px;
  border: none;
  float: left;
  width: 80%;
  border-radius: 25px 0px 0px 25px;
  background: white;
}

.btn-dt-edit-tk[data-title]:hover::after {
  /* edit button hover text */
  content: attr(data-title);
  padding-left: 18px;
  transition: all 0.3s ease-out;
  color: #7a8084;
  width: max-content;
  position: absolute;
  right: 100%;
  font-weight: bolder;
  font-size: 14px;
  top: 20px;
}

.btn-dt-edit-tk {
  /* data table edit button*/
  background: none;
  border-radius: 50%;
  padding: 7.5px;
  border: none;
}

form.example button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2196f3;
  color: white;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-radius: 0px 25px 25px 0px;
}

form.example button:hover {
  background: #0b7dda;
}

form.example::after {
  content: '';
  clear: both;
  display: table;
}

.example {
  margin: auto;
  max-width: 300px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb(116, 244, 66);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: rgb(66, 134, 244);
  background-color: rgb(96, 244, 66);
}

.space-radio {
  margin-right: 90px;
}

.icon-btn-action {
  /* Icon Action*/
  margin-right: 10px;
}

.btn-dt-action {
  /* Button Action*/
  display: block;
  background-color: #ff808b;
  border: none;
  border-radius: 16px;
  font-size: 14px;
  color: white;
  font-weight: bolder !important;
  width: 232px;
  height: 48px;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  text-align: center;
}

.btn-dt-action span,
.btn-dt-action .icon-position {
  display: block;
  text-align: center;
  position: absolute;
  margin-left: 0px;
}

.btn-dt-action span {
  line-height: inherit;
  font-size: 15px;
  margin-left: 86px;
  margin-top: -2px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-action .icon-position {
  width: 79px;
  margin-top: -9px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-action .icon-position .fa {
  font-size: 20px;
  vertical-align: middle;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.btn-dt-action.success span,
.btn-dt-action:hover span {
  opacity: 0;
}

.btn-dt-action.success .icon-position,
.btn-dt-action:hover .icon-position {
  width: 214px;
}

.btn-dt-action:hover {
  opacity: 0.9;
}



.btn-dt-save-white {
  /* Button white*/
  display: block;
  background-color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  color: #2a96ff;
  font-weight: bolder;
  width: 180px;
  height: 45px;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  text-align: center;
}

.btn-dt-save-white span,
.btn-dt-save-white .icon-position {
  display: block;
  text-align: center;
  position: absolute;
  margin-left: 0px;
}

.btn-dt-save-white span {
  line-height: inherit;
  font-size: 14px;
  margin-left: 56px;
  margin-top: -2px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-save-white .icon-position {
  width: 44px;
  margin-top: -12px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-save-white .icon-position .fa {
  font-size: 20px;
  vertical-align: middle;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.btn-dt-save-white.success span,
.btn-dt-save-white:hover span {
  left: -72%;
  opacity: 0;
  display: none;
}

.btn-dt-save-white.success .icon-position,
.btn-dt-save-white:hover .icon-position {
  width: 180px;
}

.btn-dt-save-white:hover {
  opacity: 0.9;
}

.btn-dt-save  {
  width: 180px;
  height: 45px;
  background-color: #2a96ff;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  display: block;
}

.btn-dt-save:disabled {
  width: 180px;
  height: 45px;
  background-color: #2a96ff;
  border: none;

  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  display: block;
  cursor: not-allowed;
  pointer-events: none;
}

.btn-dt-save:hover{
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-save:active{
  background: var(--princip-color-2, #0074E4);
  color:#FFF
}

.btn-dt-save-Filtre {
  padding: 10px;
  background-color: #2a96ff;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  display: block;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

}
.btn-dt-save-Filtre:hover{
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-save-Filtre:active{
  background: var(--princip-color-2, #0074E4);
  color:#FFF
}




.btn-dt-save-blue {
  /* Button Enregistrer blue*/
  width: 182px;
  height: 48px;
  background-color: #2a96ff;
  border: none;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
}
/* .btn-dt-save-blue:hover{
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-save-blue:active{
  background: var(--princip-color-2, #0074E4);
  color:#FFF
} */


.btn-dt-save-blue span,
.btn-dt-save-blue .icon-position {
  display: block;
  text-align: center;
  position: absolute;
  margin-left: 0px;
}

.btn-dt-save-blue span {
  display: block;
  text-align: center;
  position: absolute;
  color: white;
  line-height: inherit;
  font-size: 15px;
  margin-left: 56px;
  margin-top: -10px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-save-blue .icon-position {
  width: 85px;
  margin-top: -13px;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.btn-dt-save-blue.success .icon-position,
.btn-dt-save-blue:hover .icon-position {
  width: 172px;
}

.btn-dt-save-blue:hover {
  opacity: 0.9;
}

.btn-dt-save-blue:disabled {
  background-color: #c9c9c9;
}

.btn-dt-save-blue:hover span {
  opacity: 0;
}

.icon-btn-save {
  /* Icon Button Enregistrer*/
  color: white;
  margin-right: 10px;
}

.btn-dt-save-popup {
  width: 134px;
  height: 43px;
  background-color: #2a96ff;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-dt-save-popup:hover {
  border: 1px solid var(--principal-color, #2A96FF);
  background: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color:#2A96FF ;
}
.btn-dt-save-popup:active {
  background: var(--princip-color-2, #0074E4);
  color:#FFF
}

.icon-btn-save {
  /* Icon Button Enregistrer popup*/
  color: white;
  margin-right: 10px;
}

.btn-dt-cancel-popup {
  /* Button cancel popup*/
  width: 134px;
  height: 43px;
  background-color: #D1D1D1;
  border: none;
  border-radius: 15px;
  color: #212529;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
  display:flex;
  justify-content: center;
align-items: center;
}
.btn-dt-cancel-popup:hover {
  background-color: var(--Annuler-ou-cursor, #959494);
  color: #FFF;
}
.btn-dt-cancel-popup:active {
  background-color: var(--princip-color-2, #66696A);
  color:#FFF
}

.btn-dt-edit-popup {
  /* Button edit popup*/
  width: 134px;
  height: 43px;
  background-color: #FF808B;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
}
.btn-dt-edit-popup:hover{
  background-color: #FFB3B9;
}
.btn-dt-edit-popup:active{
  border: 1px solid var(--secondary-color-2, #FFB3B9);

  background-color: #FFECEE;
  color:#FF808B
}

.btn-dt-delete-popup {
  /* Button delete popup*/
  width: 134px;
  height: 43px;
  background-color: #d40606;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
}

.btn-dt-clone {
  width: 153px;
  height: 38px;
  display: block;
  background-color: #fff;
  border: 2px solid #ea243f;
  border-radius: 15px;
  color: #ea243f;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
}

.btn-dt-send {
  /* Button Envoyer*/
  width: 180px;
  height: 42px;
  background-color: #2a96ff;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
}

  /* Button Envoyer Hover*/

.btn-dt-send:hover{
  background-color: #D4EAFF;

}


.btn-dt-filter {
  /* Button Filtrer*/
  width: 68px;
  height: 29px;
  background-color: #e5e5e5;
  border-radius: 8px;
  color: #818181;
  font-size: 12px;
  border: none;
}

.icon-btn-send {
  /* icon button Envoyer*/
  width: 46px;
  height: 30px;
  margin-right: 13px;
}

.btn-dt-chevron-white {
  /* Button Left white*/
  width: 37px;
  height: 37px;
  border: none;
  background: none;
}

.btn-dt-chevron {
  /* Button Left white*/
  width: 37px;
  height: 37px;
  border: none;
  background: none;
}

.icon-menu-bleu {
  margin-right: 10px;
  color: #2a96ff;
  background-color: white;
}

.divider-menu {
  border-bottom: solid 1px grey;
}

.bleu-clr {
  color: #2a96ff;
}

/* .star {
  visibility:hidden;
  font-size:20px !important;
  cursor:pointer;
  margin-right: 18px;
  background-color: #FFCA28;
}
.star:before {
  padding-top: 0px;
  content: "\2605";
  position: absolute;
  visibility:visible;
  color: #e7d84f;
}
.star:checked:before {
  padding-top: 0px;
  content: "\2606";
  position: absolute;
} */

.star {
  display: none;
}

.star + label {
  width: 16px;
  display: inline-block;
  font-size: 25px;
  color: #e7d84f;
  padding-top: 10px;
  padding-left: 1px;
}

.star + label:before {
  content: '\f006';
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
   font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
}

.star:checked + label:before {
  content: '\f005';
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  color: #e7d84f;
  -webkit-font-smoothing: antialiased;
   font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
}

.btn-dt-add[data-title]:hover::after {
  /* add button hover text */
  content: attr(data-title);
  padding-left: 27px;
  transition: all 0.3s ease-out;
  color: #2a96ff;
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  font-weight: bolder;
  font-size: 14px;
  bottom: 7px;
}

/* Data Tables Buttons models*/

.btn-dt-add {
  /* data table add button*/
  background-color: #2a96ff;
  color: white;
    font-size: 19px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 7.5px;
  border: none;
}

.btn-dt-add:hover {
  border: 1px solid var(--principal-color, #2A96FF);
  background-color: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color: #2a96ff;
}

.btn-dt-settings[data-title]:hover::after {
  /* settings button hover text */
  content: attr(data-title);
  padding-left: 18px;
  transition: all 0.3s ease-out;
  color: #2a96ff;
  width: max-content;
  position: absolute;
  right: 100%;
  top: 34px;
  font-weight: bolder;
  font-size: 14px;
}

.btn-dt-settings {
  /* data table settings button*/
  background: none;
  border-radius: 15px;
  height: 61px;
  width: 61px;
  border: none;
}

.icon-btn-settings {
  width: 61px;
  height: 61px;
}

.icon-btn-add {
  /* icon add button*/
  color: white;
  font-size: 19px;
}
.icon-btn-add:hover {
  /* icon add button*/
  color: #2a96ff;

}

.btn-dt-edit {
  /* data table edit button*/
  border: none;
  background: none;
}

.btn-dt-delete {
  /* data table delete button*/
  border: none;
  background-color: #e60303;
  height: 28px;
  width: 28px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 17px;
  align-items: center;

}
.btn-dt-delete:hover{
background-color:#FFECEE;
color: #e60303;
}


.btn-dt-link {
  min-width: 102px;
  background-color: transparent;
  color: #2a96ff;
  border: none;
}

.icon-btn-link {
  margin-left: 5px;
}

.fa-icon-style {
  font-size: 17px;
}

.card-head {
  /* Card header */
  padding: 25px;
  border-radius: 15px;
  margin: 15px;
  border: none;
  background-color: white;
}

.details-header {
  /* Card details header */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.details-spinner {
  /* Card header details spinner*/
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: 20px;
}

.spinner-style {
  /* spinner loading */
  width: min-content;
  margin: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-dt-list {
  background: none;
  border: none;
}

.btn-dt-list:hover{
  filter: brightness(70%);
}
/* Button add file */

.add-file-contaniner {
  display: flex;
}

.add-file-icon {
  color: #2a96ff;
  font-size: 13px;
  position: relative;
  right: -32px;
  top: -3px;
}

.add-file-paper-icon {
  color: #2a96ff;
  font-size: 20px;
}

/*------------------*/

.btn-document-add {
  width: 43px;
  height: 43px;
  border: none;
  border-radius: 10px;
  background-color: #2a96ff;
  color: white;
  margin: 3px;
}
.btn-document-add:hover {
  border: 1px solid var(--principal-color, #2A96FF);
  background-color: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color: #2a96ff;
}

.btn-lock {
  width: 43px;
  height: 43px;
  border: none;
  border-radius: 10px;
  background-color: #2a96ff;
  color: white;
  margin: 3px;
}
.btn-lock:hover {
  border: 1px solid var(--principal-color, #2A96FF);
  background-color: var(--color-principal-2---bcg-bouton, #D4EAFF);
  color: #2a96ff;
}
.eyeBtn{
  border: none;
  background-color: transparent;
  position: absolute;
  right: 16px;
  bottom: 16px;
  color: #959494;
  font-size: 12px;
}
.passwordDiv{
  border-radius: 20px;
background: var(--bcg-div-interne, #EEF7FF);
padding: 5px 0px;
display: flex;
justify-content: center;
align-items: center;
}



.btn-document-add[data-title]:hover::after {
  /* button hover text */
  content: attr(data-title);
  padding-right: 18px;
  transition: all 0.3s ease-out;
  color: #2a96ff;
  width: max-content;
  position: absolute;
  font-weight: bolder;
  font-size: 14px;
  top: 11px;
  right: 100%;
}

.disable-btn {
  pointer-events: none;
  opacity: 0.5;
}

/* Permet d'aligner le bouton top-right*/

.btn-position-add {
  position: absolute;
  right: -4px;
  top: -3px;
}

.btn-keygen {
  width: 102px;
  height: 32px;
  border: none;
  background-color: #0f4a93;
  border-radius: 3px;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bolder;
}

.search {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  width: 70%;
  height: 35px;
  padding: 8px;
}

.icon-search {
  position: absolute;
  right: 10px;
  padding: 11px 12px;
  color: gray;
}

.custom-slide-togglee {
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  border: 0.5px solid #E13636;
   color: #E13636;
  background: #FFECEE;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  width: 75px;
  height: 30px;
  transition: background-color 0.3s;
}
.togglee-label {
   font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
.togglee-thumb {
  width: 25px;
  height: 25px;
  background-color: #ff6635;
  border-radius: 50%;
  margin-right: 5px;
  transition: transform 0.3s;
  margin-left: -2px;
}

.custom-slide-togglee.checked {
  background-color: #d7efff;
  border: 0.5px solid #2a96ff;
}

.custom-slide-togglee.checked .togglee-thumb {
  transform: translateX(43px);
  background-color: #2a96ff; /* Move the thumb to the right when checked */
}

.custom-slide-togglee.checked .togglee-label {
  transform: translateX(-20px);
  color: #2a96ff; /* Change the text color when checked */
}

.TypeContratt {
  display: flex;
  align-content: center;
  align-items: center;
  min-height: 84px !important;
  border-radius: 10px;
  border: 1px dashed #2a96ff;
  background: #eff7ff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.changeStatus {
  background-color: #FF808B;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  color: white;
  font-weight: bolder !important;
  width: 232px;
  height: 48px;
  cursor: pointer;
  line-height: 4px;
  float: right;
  display: block;


}
.changeStatus:hover{
  background-color: #ffa1a9;
}
.changeStatus:active{
background-color: #FFECEE;
border: 1px solid var(--secondary-color, #FF808B);

color:#FF808B
}



.filter-image {
  width: 14;
  height: 20;
}
.statut-envoieCancel {
  padding: 10px;
  background-color: #212529;
  color: #fff;
  border-radius: 15px;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.statut-envoieCancel:hover{
  background-color: #959494;
  color: #fff;

}
.statut-envoieCancel:active{
  background-color: #D1D1D1;
  color: #212529;
}


/*
.preuveDepot {
  width: 43px;
  height: 43px;
  padding: 8px;
  background-color: rgba(255, 128, 139, 1);
  color: #fff;
  border-radius: 15px;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  cursor: pointer;
}

.preuveDepot:hover{
  background-color: rgba(255, 236, 238, 1);
  color: rgba(149, 148, 148, 1);

}
.preuveDepot:active{
  background-color: rgba(255, 236, 238, 1);
  color: rgba(255, 128, 139, 1);
  border: 1px solid rgba(255, 128, 139, 1);
}
.preuveDepot:disabled{
  background-color: rgba(255, 128, 139, 1);
  color: #fff;
  opacity: 30%;
 pointer-events: none;
}

.preuveDepot[data-title]:hover::after {
  content: attr(data-title);
  padding-right: 18px;
  transition: all 0.3s ease-out;
  color: rgba(255, 128, 139, 1);
  width: max-content;
  position: absolute;
  font-weight: bolder;
  font-size: 14px;
  top: 11px;
  right: 100%;
} */


.btn-dt-retourr {
  /* Button retour à la liste*/
  display: block;
  background-color:#195A99;
  border: none;
  border-radius: 40px;
  font-size: 14px;
  color: white;
  font-weight: bolder;
  height: 42px;
  width: 42px;
  cursor: pointer;
  line-height: 4px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
}
.btn-dt-retourr:hover{
  background-color: #D4EAFF;
  color: #2A96FF;
}
.btn-dt-retourr:active{
background-color: #2A96FF;
color:#fff
}

.filtrerBtnHeader {
  width: 134px;
  height: 43px;
  background-color: #2a96ff;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  display: block;
  justify-content: center;
  align-items: center;
}

.togglee-thumb {
  width: 25px;
  height: 25px;
  background-color: #E13636;
  border-radius: 50%;
  margin-right: 5px;
  transition: transform 0.3s;
  margin-left: -2px;
}

.add-btn-searchOpp {
  border-radius: 15px;
  color: #2A96FF;
  border: 1px solid #2A96FF;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  right: 19px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px;
}
