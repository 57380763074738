.mediaViewInfo {
  --web-view-name: les différents input;
  --web-view-id: les_diffrents_input;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}

:root {
  --web-view-ids: les_diffrents_input;
}

.Liblle {
  right: 230px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: right;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(71, 71, 71, 1);
}

.Liblle-switch {
  right: 230px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 47px;
  white-space: nowrap;
  text-align: right;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(71, 71, 71, 1);
}

.switch-m {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.switch-m input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-m {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #838897;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 35px;
  height: 1.2em;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFECEE;
  border: 1px solid #e60303;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 40px;
  height: 19px;
}


.slider-m:before {
  position: absolute;
  content: '';
  height: 0.95em;
  width: 0.95em;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 0.95em;
  width: 0.95em;
  left: 2px;
  bottom: 2px;
  background-color: #e60303;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}


input:checked + .slider-m {
  background-color: #99dd71;
}

input:checked + .slider {
  background-color: #E6F6EB;
  border: 1px solid #16C046;
}

input:focus + .slider-m {
  box-shadow: 0 0 1px #99dd71;
}

input:focus + .slider {
  box-shadow: 0 0 1px #99dd71;
}

input:checked + .slider-m:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -8px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -3px;
  background-color: #16C046;
}


/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider-m.round {
  border-radius: 34px;
}

.slider-m.round:before {
  border-radius: 50%;
}

.slider.round:before {
  border-radius: 50%;
}

.input-width {
  width: 350px;
}

.container-switch {
  background-color: rgba(250, 250, 250, 1);
  /*width: 1000px;*/
  height: 69px;
  left: 0px;
  top: 72px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding-top: 20px;
  padding-left: 50px;
  margin-left: 30px;
  border-radius: 30px;
}

.select-label:hover {
  color: whitesmoke;
  background-color: rgba(42, 150, 255, 0.85);
}

.select-label {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  color: rgba(42, 150, 255, 0.85);
  width: 170px;
  cursor: pointer;
}

.mr-top {
  margin-top: 20px;
}

.space-switch {
  margin-left: 150px;
}

.img {
  transition: transform 0.25s ease;
  cursor: zoom-in;
  width: 40px;
  height: 40px;
}

::ng-deep .mat-input-element,
.mat-input-element::-webkit-search-cancel-button,
.mat-input-element::-webkit-search-decoration,
.mat-input-element::-webkit-search-results-button,
.mat-input-element::-webkit-search-results-decoration {
  font-size: 13px !important;
  font-family:  'Nunito Sans', sans-serif !important;
}

::ng-deep .mat-select-trigger {
  font-size: 13px !important;
  font-family:  'Nunito Sans', sans-serif !important;
}

::ng-deep * {
  font-family:  'Nunito Sans', sans-serif !important;
}

.input-radio {
  width: 16px;
  height: 16px;
  margin-right: 20px;
}
